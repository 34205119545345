(function(){
    "use strict";

    angular.module("app")
        .run(function($rootScope) {
            if (!window.loading_screen) {
                return;
            }

            $rootScope.$on('_view:urlReady', function() {
                window.loading_screen.finish();
            });
        });
}());